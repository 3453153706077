import React from 'react';
import { convertToProducts } from 'bootstrap/data/product/Product.utils';
import { Payment } from 'subscription/payment/Payment';
import { SingleProductPayment } from 'subscription/singleproduct/SingleProductPayment';
import { withDefaultProduct } from 'subscription/withDefaultProduct/withDefaultProduct';
import { useFeatures } from 'useFeatures';

export const Subscription = withDefaultProduct(({ product, products }) => {
  const { isPreselectProductOffer } = useFeatures();
  return (
    <>
      {isPreselectProductOffer ? (
        <Payment products={convertToProducts(products)} />
      ) : (
        <SingleProductPayment defaultProduct={product} products={products} />
      )}
    </>
  );
});
