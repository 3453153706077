import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { setAnswer } from 'bootstrap/onboarding/actions';
import { placeholderFormatter } from 'onboarding/data/constants/Placeholders';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { SyntheticEvent, useEffect, useState } from 'react';
import { OptionQuestion } from './OptionQuestion';
import { format } from './OptionQuestionComponent.utils';
import { TextOption } from './TextOption';
import { useFeatures } from 'useFeatures';

export const useOptionQuestionLogic = (question: OptionQuestion) => {
  const dispatch = useAppDispatch();

  const features = useFeatures();

  const { answers } = useAppSelector((state) => state.onboarding);
  const age = placeholderFormatter(answers, QuestionIds.AboutYouAge);
  const gender = placeholderFormatter(answers, QuestionIds.AboutYouGender);

  const [activeOptions, setActiveOptions] = useState<string[]>([]);
  const [containerStyle, setContainerStyle] = useState<string>('');
  const [title, setTitle] = useState(question.title);
  const [isLeaving, setLeaving] = useState<boolean>(false);

  useEffect(() => {
    setTitle(format(question.title, answers));
    const currentAnswer = answers[question.id];
    setContainerStyle('enter');
    setLeaving(false);
    if (currentAnswer) {
      if (
        typeof currentAnswer === 'string' &&
        question.hasOptionId(currentAnswer)
      ) {
        setActiveOptions([currentAnswer]);
      } else if (Array.isArray(currentAnswer)) {
        setActiveOptions(
          (currentAnswer as string[]).filter((it) => question.hasOptionId(it)),
        );
      } else {
        setActiveOptions([]);
      }
    } else {
      setActiveOptions([]);
    }
  }, [question]);

  const optionHandler = (option: TextOption) => (e: SyntheticEvent) => {
    e.preventDefault();
    if (question.multiple) {
      if (activeOptions.includes(option.id)) {
        setActiveOptions(activeOptions.filter((id) => id !== option.id));
      } else {
        setActiveOptions([...activeOptions, option.id]);
      }
    } else if (!isLeaving) {
      setActiveOptions([option.id]);
      setLeaving(true);
      setTimeout(() => {
        setContainerStyle('leave');
        setTimeout(() => {
          appAnalytics.trackEvent(new SaveAnswer(question.id, option.id));
          dispatch(setAnswer(question.id, option.id, features));
        }, 100);
      }, 200);
    }
  };

  const nextHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isLeaving) {
      setContainerStyle('leave');
      setLeaving(true);
      setTimeout(() => {
        appAnalytics.trackEvent(new SaveAnswer(question.id, activeOptions));
        dispatch(setAnswer(question.id, activeOptions, features));
      }, 100);
    }
  };

  return {
    containerStyle,
    title,
    age,
    gender,
    activeOptions,
    optionHandler,
    nextHandler,
  };
};
