import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from 'assets/logo_loona.svg';
import { getLocaleIntervalTimeString } from 'utils/utils';
import { useRmeqTestLogic } from '../MeqResults.hook';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import { useFeatures } from 'useFeatures';
import styles from './styles.module.scss';

export const MeqResultsV2 = () => {
  const { t } = useTranslation();
  const { rmeqTestResult, type, nextButtonHandler } = useRmeqTestLogic();

  const { title, description, wakeUpTime, bedTime, background, stickerImg } =
    rmeqTestResult;

  const { isMeqResultAnimalFlow } = useFeatures();

  return (
    <div
      className={`
        ${styles.container} 
        ${isMeqResultAnimalFlow ? styles.animal : ''} 
        ${styles[type.toLowerCase()]}
      `}
    >
      <Logo className={styles.logo} />
      <div className={styles.contentWrapper}>
        {!isMeqResultAnimalFlow && (
          <img src={background} className={styles.background} alt="" />
        )}
        <div className={styles.subtitle}>{t('RMeqResultTitle')}</div>
        <div className={styles.title}>{t(title)}</div>
        <img src={stickerImg} className={styles.sticker} alt="" />
        <div className={styles.description}>{t(description)}</div>
        <div className={`${styles.cardContainer} ${styles.bedTimeCard}`}>
          <div className={styles.label}>{t('RMeqResultLabel1')}</div>
          <div className={styles.time}>
            {getLocaleIntervalTimeString(bedTime)?.toLowerCase()}
          </div>
        </div>
        <div className={`${styles.cardContainer} ${styles.wakeUpCard}`}>
          <div className={styles.label}>{t('RMeqResultLabel2')}</div>
          <div className={styles.time}>
            {getLocaleIntervalTimeString(wakeUpTime)?.toLowerCase()}
          </div>
        </div>
        <div className={styles.figcaption}>{t('RMeqResultFigcaption')}</div>
        <StickyContainer className={styles.stickyContainer}>
          <button className={styles.button} onClick={nextButtonHandler}>
            {t('RmeqResultStartPlanButton')}
          </button>
        </StickyContainer>
      </div>
      <div className={styles.note}>{t('RmeqResultNote')}</div>
    </div>
  );
};
