import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { appAnalytics } from 'analytics';
import { OnboardingScreenOpened } from 'analytics/AllEvents';
import { pushWithSearchQuery } from 'utils';
import { Question } from './question/Question';
import { getQuestionComponent } from './question/helper';
import { StepperWrapper } from './StepperWrapper';
import { QuestionIds } from './data/constants/QuestionIds';
import { useFeatures } from 'useFeatures';
import { getQuestionsOrder } from 'onboarding/data/QuestionsOrder/QuestionsOrder';

import styles from './styles.module.scss';
import { getQuestionsMeta } from './data/meta';

type OnboardingParams = {
  questionId: QuestionIds;
};

export const Onboarding = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const features = useFeatures();

  const {
    isAdhdTest,
    isDefaultOnboarding,
    isGadTest,
    isFlexibleOmitViewEventsFlow,
    isMeqResultAnimalFlow,
    isMeqResultV2Flow,
  } = features;

  const questionMeta = getQuestionsMeta(
    isAdhdTest,
    isGadTest,
    isDefaultOnboarding,
  );

  const { questionId } = useParams<OnboardingParams>();
  const [className, setClassName] = React.useState('');

  const questionsOrder = getQuestionsOrder(features);

  useEffect(() => {
    setClassName('');
  }, [location, questionId]);

  useEffect(() => {
    if (QuestionIds[questionId] && !isFlexibleOmitViewEventsFlow) {
      appAnalytics.trackEvent(
        new OnboardingScreenOpened(questionMeta[questionId].id),
      );
    }
  }, [questionId]);

  if (!QuestionIds[questionId]) {
    dispatch(pushWithSearchQuery(`/onboarding/${questionsOrder[0]}`));
    return null;
  }

  const question: Question = questionMeta[questionId];
  const QuestionComponent = getQuestionComponent(
    question,
    isMeqResultAnimalFlow,
    isMeqResultV2Flow,
  );
  return (
    <div className={`${styles.container} ${styles[className]}`}>
      {question.hasStepper && (
        <StepperWrapper questionId={questionId} className={className} />
      )}
      {QuestionComponent && <QuestionComponent question={question} />}
    </div>
  );
};
