import { Product } from 'bootstrap/data/product/Product';
import { isGiftFlow } from 'useFeatures';

export const getApplePayLabel = (product: Product): string => {
  if (isGiftFlow()) {
    return 'Loóna Gift Card';
  }

  if (product.trialLength > 0) {
    return `${product.trialLength} ${product.trialUnit} Trial`;
  } else {
    return ``;
  }
};

export const getApplePayPrice = (product: Product): string => {
  if (isGiftFlow()) {
    // @ts-ignore
    return product.price?.toString() || '0';
  }
  if (product.trialLength > 0) {
    return product.setupFee?.toString() || '0';
  } else {
    return product?.amount.toString() || '0';
  }
};
