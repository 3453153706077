import React, { ChangeEvent, SyntheticEvent, useRef, useState } from 'react';
import {
  CardCvvElement,
  CardMonthElement,
  CardNumberElement,
  CardYearElement,
  IndividualElementChangeEvent,
  useRecurly,
} from '@recurly/react-recurly';
import { appAnalytics } from 'analytics';
import { CardFormInputStarted, Subscribe } from 'analytics/AllEvents';
import Question from 'assets/ic_question.svg';
import QuestionDark from 'assets/ic_question_dark.svg';
import { Product } from 'bootstrap/data/product/Product';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import {
  createSubscription,
  createSubscriptionByIdx,
} from 'bootstrap/net/subscription';
import { setTrialOfferShown } from 'bootstrap/user/actions/setTrialOfferInfo';
import Alert from 'components/error/Error';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import { getQueryVariable, pushWithSearchQuery } from 'utils';
import { ThreeDSecure } from '../../threedsecure/ThreeDSecure';
import { Tooltip } from '../../tooltip/Tooltip';
import Countries from '../countries.json';
import { ELEMENTS_STYLES, ELEMENTS_STYLES_DARK } from '../elementsStyles';
import { TFunction } from 'react-i18next';
import { setCountryCode, setCountryCurrency } from 'bootstrap/user/actions';
import { SuccessPaymentProps } from '../../../Recurly.types';
import { buyProduct } from 'bootstrap/giftpurchase/actions/buyProduct';
import { setSubscriptionToken } from 'bootstrap/user/actions/setUserInfo';
import { withRecaptcha } from '../../withRecaptcha/withRecaptcha';
import styles from './styles.module.scss';
import { isGiftFlow, useFeatures } from 'useFeatures';

type Props = {
  product: Product;
  className?: string;
  darkTheme?: boolean;
  children?: JSX.Element | JSX.Element[];
  onSuccess: (params: SuccessPaymentProps) => void;
  onError: (message: string) => void;
  translation?: TFunction;
};

export const DefaultCardForm = ({
  product,
  className,
  darkTheme = false,
  children,
  onSuccess,
  onError,
  translation: t,
}: Props) => {
  const recurly = useRecurly();
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);

  const { countryCode, currency, paymentMethod, subscriptionToken } =
    useAppSelector((state) => state.user);

  const { isAdhdTest, isLocalCurrencyEnabled, isWorkbookUpsellFlow } =
    useFeatures();

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [firstNameValid, setFirstNameValid] = useState(false);
  const [lastNameValid, setLastNameValid] = useState(false);
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [postalCodeValid, setPostalCodeValid] = useState(false);
  const [cardNumberEmpty, setCardNumberEmpty] = useState(true);
  const [monthValid, setMonthValid] = useState(false);
  const [monthEmpty, setMonthEmpty] = useState(true);
  const [yearValid, setYearValid] = useState(false);
  const [yearEmpty, setYearEmpty] = useState(true);
  const [cvvValid, setCvvValid] = useState(false);
  const [cvvEmpty, setCvvEmpty] = useState(true);
  const [error, setError] = useState('');
  const [actionTokenId, setActionTokenId] = useState('');
  const [isLoading, setLoading] = useState(false);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const postalCodeRef = useRef<HTMLInputElement>(null);

  const [selectedProduct, setSelectedProduct] = useState(product);
  const [firstFormFocusHandled, setFirstFormFocusHandled] = useState(false);

  const handleSubmitGiftFlow = withRecaptcha(
    async (recaptchaToken: string) => {
      if (isLoading) return;

      setLoading(true);
      setError('');
      appAnalytics.trackEvent(new Subscribe());

      const form = formRef.current;
      if (!form) return;

      recurly.token(form, (err, token) => {
        if (err != null) {
          setLoading(false);
          setError(err.message);
        } else {
          dispatch(setSubscriptionToken(token.id));

          dispatch(
            buyProduct({
              product: product as any,
              token: token.id,
              recaptchaToken,
            }),
          )
            .then(() => {
              setLoading(false);
              dispatch(pushWithSearchQuery('/gift/done'));
            })
            .catch((error) => {
              setLoading(false);
              setError(error);
            });
        }
      });
    },
    setLoading,
    setError,
  );

  const handleSubscription = (recaptchaToken: string) => {
    const form = formRef.current;
    if (!form) return;

    recurly.token(form, (err, token) => {
      if (err != null) {
        setLoading(false);
        setError(err.message);
      } else {
        dispatch(setSubscriptionToken(token.id));

        subscribe(token.id, product, recaptchaToken);
      }
    });
  };

  const handleSubmit = withRecaptcha(
    async (recaptchaToken: string) => {
      if (isLoading) return;

      setLoading(true);
      setError('');
      appAnalytics.trackEvent(new Subscribe());

      handleSubscription(recaptchaToken);
    },
    setLoading,
    setError,
  );

  // CODE REFACTOR AFTER ROLLED/REMOVED CARD FORM V2

  const subscribe = (
    token: string,
    currentProduct: Product,
    recaptchaToken: string,
  ) => {
    const firstName = firstNameRef.current?.value;
    const lastName = lastNameRef.current?.value;
    const zip = postalCodeRef.current?.value;
    const idx = getQueryVariable('idx');

    const createSubscriptionPromise = !!idx
      ? createSubscriptionByIdx({
          product: currentProduct,
          token,
          bundle: getQueryVariable('bundle'),
          idx,
          paymentMethod,
          recaptchaToken,
        })
      : createSubscription({
          product: currentProduct,
          token,
          currency,
          paymentMethod,
          recaptchaToken,
        });

    createSubscriptionPromise
      .then(async (result) => {
        if (!!result.three_d_secure_action_token_id) {
          setActionTokenId(result.three_d_secure_action_token_id);
        } else {
          if (isWorkbookUpsellFlow || isAdhdTest) {
            dispatch(pushWithSearchQuery('/workbook'));
          } else {
            dispatch(setTrialOfferShown(true));
          }
          setLoading(false);
          onSuccess({
            orderId: result.purchase.order_id,
            productBought: currentProduct,
            zip,
            firstName,
            lastName,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === 'USER_NOT_FOUND') {
          dispatch(pushWithSearchQuery('/quick-purchase/login', ['idx']));
        } else {
          setError(error.debug_text);
        }
        onError(error.debug_text);
      });
    setSelectedProduct(currentProduct);
  };

  const handleThreeDSecureError = (error: string) => {
    setActionTokenId('');
    setError(error);
    onError(error);
  };

  const handleChangeCountry = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = Countries.find(
      (item) => item.code && item.code === event.target.value,
    );
    if (!selectedCountry) return;

    dispatch(setCountryCode(selectedCountry.code));

    if (isLocalCurrencyEnabled) {
      dispatch(setCountryCurrency(selectedCountry.currencyCode));
    }
  };

  // CODE REFACTOR AFTER ROLLED/REMOVED CARD FORM V2

  const handleThreeDSecureToken = withRecaptcha(
    // @ts-ignore
    async (recaptchaToken: string, threeDSToken: string) => {
      setActionTokenId('');
      setLoading(true);
      const idx = getQueryVariable('idx');
      const firstName = firstNameRef.current?.value;
      const lastName = lastNameRef.current?.value;
      const zip = postalCodeRef.current?.value;

      const createSubscriptionPromise = !!idx
        ? createSubscriptionByIdx({
            product: selectedProduct,
            token: subscriptionToken || '',
            bundle: getQueryVariable('bundle'),
            idx,
            threeDSToken,
            paymentMethod,
            recaptchaToken,
          })
        : createSubscription({
            product: selectedProduct,
            token: subscriptionToken,
            currency,
            threeDSToken,
            paymentMethod,
            recaptchaToken,
          });

      createSubscriptionPromise
        .then(async (result) => {
          setLoading(false);
          onSuccess({
            orderId: result.purchase.order_id,
            productBought: selectedProduct,
            zip,
            firstName,
            lastName,
          });
        })
        .catch((error) => {
          setLoading(false);
          if (!!error.actionTokenId) {
            setActionTokenId(error.actionTokenId);
          } else if (error.code === 'USER_NOT_FOUND') {
            dispatch(pushWithSearchQuery('/quick-purchase/login', ['idx']));
            onError(error);
          } else {
            setError(error.debug_text.email);
            onError(error);
          }
        });
    },
    setLoading,
    setError,
  );

  const toggleCvvTooltip = (e: SyntheticEvent) => {
    e.stopPropagation();
    setTooltipVisible(!tooltipVisible);
  };

  const handleFormInputFocus = () => {
    if (!firstFormFocusHandled) {
      appAnalytics.trackEvent(new CardFormInputStarted());
      setFirstFormFocusHandled(true);
    }
  };

  const elementsStyles = darkTheme ? ELEMENTS_STYLES_DARK : ELEMENTS_STYLES;

  return (
    <form
      ref={formRef}
      className={`${styles.container} ${className} ${
        darkTheme ? styles.dark : ''
      }`}
    >
      <Alert visible={!!error} error={error} onClose={() => setError('')} />

      <ThreeDSecure
        token={actionTokenId}
        // @ts-ignore
        onSuccess={handleThreeDSecureToken}
        onError={handleThreeDSecureError}
      />

      <div className={styles.fieldsContainer}>
        <input
          ref={firstNameRef}
          type="text"
          data-recurly="first_name"
          placeholder={t ? t('firstNamePlaceholder') : 'First Name'}
          className={styles.input}
          onChange={(event) => {
            setFirstNameValid(event.target.value.length > 0);
          }}
          onFocus={handleFormInputFocus}
        />
        <span className={styles.dateDivider} />
        <input
          ref={lastNameRef}
          type="text"
          data-recurly="last_name"
          placeholder={t ? t('lastNamePlaceholder') : 'Last Name'}
          className={styles.input}
          disabled={isLoading}
          onChange={(event) => {
            setLastNameValid(event.target.value.length > 0);
          }}
          onFocus={handleFormInputFocus}
        />
      </div>

      <div>
        <CardNumberElement
          className={cardNumberEmpty || cardNumberValid ? '' : 'invalid'}
          style={
            cardNumberEmpty
              ? elementsStyles.cardNumber.empty
              : cardNumberValid
              ? elementsStyles.cardNumber.default
              : elementsStyles.cardNumber.invalid
          }
          onChange={(event: IndividualElementChangeEvent) => {
            setCardNumberValid(event.valid);
            setCardNumberEmpty(event.length === 0);
          }}
          onFocus={handleFormInputFocus}
        />
        {!cardNumberEmpty && !cardNumberValid && (
          <div className={styles.formError}>
            {t ? t('cardNumberError') : 'Please enter a valid card number'}
          </div>
        )}
      </div>

      <div className={styles.fieldsContainer}>
        <div>
          <CardMonthElement
            className={monthEmpty || monthValid ? '' : styles.invalid}
            style={
              monthEmpty
                ? elementsStyles.month.empty
                : monthValid
                ? elementsStyles.month.default
                : elementsStyles.month.invalid
            }
            onChange={(event: IndividualElementChangeEvent) => {
              setMonthValid(event.valid);
              setMonthEmpty(event.length === 0);
            }}
            onFocus={handleFormInputFocus}
          />
          {!monthEmpty && !monthValid && (
            <div className={styles.formError}>
              {t
                ? t('expirationMonthError')
                : 'Expiration month should be between 1 and 12'}
            </div>
          )}
        </div>
        <span className={styles.dateDivider}>/</span>
        <div>
          <CardYearElement
            className={yearEmpty || yearValid ? '' : styles.invalid}
            style={
              yearEmpty
                ? elementsStyles.year.empty
                : yearValid
                ? elementsStyles.year.default
                : elementsStyles.year.invalid
            }
            onChange={(event: IndividualElementChangeEvent) => {
              setYearValid(event.valid);
              setYearEmpty(event.length === 0);
            }}
            onFocus={handleFormInputFocus}
          />
          {!yearEmpty && !yearValid && (
            <div className={styles.formError}>
              {t
                ? t('expirationYearError', { year: new Date().getFullYear() })
                : `Expiration year should be ${new Date().getFullYear()} or later`}
            </div>
          )}
        </div>
      </div>

      <div className={styles.fieldsContainer}>
        <div className={styles.cvvWrapper}>
          <CardCvvElement
            className={styles.cvvElement}
            style={
              cvvEmpty ? elementsStyles.cvv.empty : elementsStyles.cvv.default
            }
            onChange={(event: IndividualElementChangeEvent) => {
              setCvvValid(event.valid);
              setCvvEmpty(event.length === 0);
            }}
            onFocus={handleFormInputFocus}
          />
          <img
            className={`${styles.questionMark} ${
              cvvValid ? '' : styles.visible
            }`}
            src={darkTheme ? QuestionDark : Question}
            alt="?"
            onClick={toggleCvvTooltip}
          />

          <Tooltip
            isVisible={tooltipVisible}
            onClose={() => setTooltipVisible(false)}
          />
        </div>
        <span className={styles.dateDivider} />
        <input
          ref={postalCodeRef}
          type="text"
          data-recurly="postal_code"
          placeholder={t ? t('postalCodePlaceholder') : 'Postal code'}
          className={styles.input}
          onChange={(event) => {
            setPostalCodeValid(event.target.value.length > 0);
          }}
          onFocus={handleFormInputFocus}
        />
      </div>
      <select
        className={styles.countrySelector}
        data-recurly="country"
        defaultValue={countryCode || 'US'}
        onFocus={handleFormInputFocus}
        onChange={handleChangeCountry}
      >
        {Countries.map((country) => (
          <option key={country.name} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>

      {children}

      <LoadingButton
        disabled={
          !(
            firstNameValid &&
            lastNameValid &&
            cardNumberValid &&
            monthValid &&
            yearValid &&
            postalCodeValid
          )
        }
        isLoading={isLoading}
        label={t ? t('continueSecurely') : 'Continue Securely'}
        className={styles.button}
        darkTheme={darkTheme}
        onClick={isGiftFlow() ? handleSubmitGiftFlow : handleSubmit}
      />
    </form>
  );
};
