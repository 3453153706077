import { RefObject, useEffect, useState } from 'react';
import { appAnalytics } from 'analytics';
import {
  ContinueUnfoldPayment,
  SubscriptionError,
  SubscriptionSuccess,
} from 'analytics/AllEvents';
import { Amplitude } from 'analytics/Amplitude';
import { Product } from 'bootstrap/data/product/Product';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { getBundle } from 'bootstrap/net/product';
import {
  setCountryCode,
  setCountryCurrency,
  setSubscriptionActive,
} from 'bootstrap/user/actions';
import { ViewQPScreen } from 'quickpurchase/analytics';
import { SuccessPaymentProps } from 'subscription/recurly/Recurly.types';
import { getQueryVariable, pushWithSearchQuery } from 'utils';
import { DEFAULT_BUNDLE_ID } from './Payment.constants';
import { useFeatures } from 'useFeatures';

export const usePaymentLogic = (paymentFormRef?: RefObject<HTMLDivElement>) => {
  const dispatch = useAppDispatch();
  const { currency } = useAppSelector((state) => state.user);

  const { isLocalCurrencyEnabled } = useFeatures();

  const [product, setProduct] = useState<Product>();
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const { email } = useAppSelector((state) => state.user);

  useEffect(() => {
    const idx = getQueryVariable('idx');
    const bundle = getQueryVariable('bundle');
    loadProduct(bundle);
    dispatch(setCountryCode());

    if (isLocalCurrencyEnabled) {
      dispatch(setCountryCurrency());
    }

    if (idx) {
      Amplitude.setUserId(idx);
    } else {
      dispatch(pushWithSearchQuery('/quick-purchase/login', ['idx']));
    }

    appAnalytics.trackEvent(new ViewQPScreen('qpPayment', bundle));
  }, []);

  const loadProduct = (id: string = DEFAULT_BUNDLE_ID) => {
    setError(false);
    getBundle(id)
      .then((p) => {
        setProduct(p);
      })
      .catch((_) => {
        if (id === DEFAULT_BUNDLE_ID) {
          setError(true);
        } else {
          loadProduct(DEFAULT_BUNDLE_ID);
        }
      });
  };

  const handlePayPalSuccess = ({
    orderId,
    productBought,
  }: SuccessPaymentProps) => {
    dispatch(setSubscriptionActive(false));
    product &&
      appAnalytics.trackEvent(
        new SubscriptionSuccess(
          productBought.trialLength
            ? productBought.setupFee
            : productBought.amount,
          productBought.currency,
          productBought.code,
          {
            eventId: orderId,
            email: email,
            onboardingType: 'qp',
          },
        ),
      );
    dispatch(pushWithSearchQuery('/quick-purchase/done'));
  };

  const handlePayPalError = (message: string) => {
    appAnalytics.trackEvent(new SubscriptionError(message));
  };

  const handleCardSuccess = ({
    orderId,
    productBought,
    zip,
    firstName,
    lastName,
  }: SuccessPaymentProps) => {
    dispatch(setSubscriptionActive(false));
    product &&
      appAnalytics.trackEvent(
        new SubscriptionSuccess(
          productBought.trialLength
            ? productBought.setupFee
            : productBought.amount,
          productBought.currency,
          productBought.code,
          {
            eventId: orderId,
            email: email,
            zip: zip,
            firstName: firstName,
            lastName: lastName,
            onboardingType: 'qp',
          },
        ),
      );
    dispatch(pushWithSearchQuery('/quick-purchase/done'));
  };

  const handleCardError = (message: string) => {
    appAnalytics.trackEvent(new SubscriptionError(message));
  };

  const handleRetry = () => {
    loadProduct(getQueryVariable('bundle'));
  };

  const scrollToPaymentForm = () => {
    setExpanded(true);

    setTimeout(() => {
      if (!paymentFormRef) return;

      const contentHeightBeforePaymentForm = 100;
      const paymentFormOffsetTop = paymentFormRef.current?.offsetTop || 0;
      const topPosition = +(
        paymentFormOffsetTop - contentHeightBeforePaymentForm
      );

      if (paymentFormRef.current) {
        window.scroll({
          top: topPosition,
          behavior: 'smooth',
        });
      }
    }, 1);
  };

  const handleGetPlanClick = () => {
    appAnalytics.trackEvent(new ContinueUnfoldPayment(''));
    scrollToPaymentForm();
  };

  return {
    error,
    product,
    currency,
    expanded,
    handlePayPalSuccess,
    handlePayPalError,
    handleCardSuccess,
    handleCardError,
    handleRetry,
    handleGetPlanClick,
  };
};
