import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { setAnswer } from 'bootstrap/subscriptioncancel/actions/answer';
import { SyntheticEvent, useEffect, useState } from 'react';
import { OptionQuestion } from './OptionQuestion';
import { TextOption } from './TextOption';
import {
  cancelSubscriptionPlan,
  changeSubscriptionPlan,
} from 'bootstrap/user/actions';
import { push } from 'connected-react-router';
import { getErrorMessage } from 'bootstrap/net/commonnet';
import { appAnalytics } from 'analytics';
import {
  ContinueSubscriptionCancel,
  ContinueSubscriptionCancelHowLikely,
  ContinueSubscriptionCancelReason,
  SubscriptionSwitchSuccess,
} from 'cancelsubscription/analytics';
import { QuestionIds } from 'cancelsubscription/data/constants/QuestionsIds';
import { useFeatures } from 'useFeatures';

export const useOptionQuestionLogic = (question: OptionQuestion) => {
  const dispatch = useAppDispatch();
  const { answers } = useAppSelector((state) => state.subscriptionCancel);
  const features = useFeatures();

  const [error, setError] = useState<string>('');
  const [activeOptions, setActiveOptions] = useState<string[]>([]);
  const [containerStyle, setContainerStyle] = useState<string>('');

  const [title, setTitle] = useState(question.title);
  const [isLeaving, setLeaving] = useState<boolean>(false);

  const trackEvents = (option: TextOption) => {
    switch (question.id) {
      case QuestionIds.Reason: {
        appAnalytics.trackEvent(
          new ContinueSubscriptionCancelReason(option.id),
        );
        break;
      }
      case QuestionIds.HowLikely: {
        appAnalytics.trackEvent(
          new ContinueSubscriptionCancelHowLikely(option.id),
        );
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    setTitle(question.title);
    const currentAnswer = answers[question.id];
    setContainerStyle('enter');
    setLeaving(false);
    if (currentAnswer) {
      if (
        typeof currentAnswer === 'string' &&
        question.hasOptionId(currentAnswer)
      ) {
        setActiveOptions([currentAnswer]);
      } else if (Array.isArray(currentAnswer)) {
        setActiveOptions(
          (currentAnswer as string[]).filter((it) => question.hasOptionId(it)),
        );
      } else {
        setActiveOptions([]);
      }
    } else {
      setActiveOptions([]);
    }
  }, [question]);

  const optionHandler = (option: TextOption) => async (e: SyntheticEvent) => {
    e.preventDefault();
    if (isLeaving) {
      return;
    }

    setActiveOptions([option.id]);

    const handleOption = async () => {
      switch (option.id) {
        case 'yes_change_subscription':
          const planCode = 'co-loona-web-ar-q-t0-14-c75-59';

          dispatch(changeSubscriptionPlan(planCode))
            .then(() => {
              appAnalytics.trackEvent(new SubscriptionSwitchSuccess(planCode));
              dispatch(setAnswer(question.id, [option.id], features));

              setContainerStyle('leave');
              setLeaving(true);
              dispatch(push(QuestionIds.Loader));
            })
            .catch((error) => {
              setError(getErrorMessage(error));
            });
          break;
        case 'no_change_subscription':
          dispatch(cancelSubscriptionPlan())
            .then(() => {
              appAnalytics.trackEvent(new ContinueSubscriptionCancel());
              dispatch(push(QuestionIds.FinishUserLost));
            })
            .catch((error) => setError(error));
          break;
        default:
          dispatch(setAnswer(question.id, [option.id], features));
          setContainerStyle('leave');
          setLeaving(true);
          break;
      }
    };

    trackEvents(option);
    setTimeout(handleOption, 200);
  };

  return {
    containerStyle,
    title,
    activeOptions,
    error,
    setError,
    optionHandler,
  };
};
