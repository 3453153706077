import { SyntheticEvent, useEffect, useState } from 'react';
import { EducationAnswer } from './EducationAnswer';
import { SaveAnswer } from 'analytics/AllEvents';
import { appAnalytics } from 'analytics';
import { goToNextQuestion } from 'bootstrap/onboarding/actions';
import { useSubmit } from 'hooks/useSubmit';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { useFeatures } from 'useFeatures';

export const useEducationAnswerLogic = (question: EducationAnswer) => {
  const dispatch = useAppDispatch();
  const { answers } = useAppSelector((state) => state.onboarding);

  const [containerStyle, setContainerStyle] = useState('');

  const features = useFeatures();

  const { title, description, correctAnswerId } = question;
  const questionIdForAnswer = question.id.split('Answer')[0] as QuestionIds;
  const isCorrectAnswer = answers[questionIdForAnswer] === correctAnswerId;

  useEffect(() => {
    setContainerStyle('enter');
  }, []);

  const nextHandler = useSubmit((e: SyntheticEvent) => {
    e.preventDefault();
    setContainerStyle('leave');
    setTimeout(() => {
      appAnalytics.trackEvent(new SaveAnswer(question.id));
      dispatch(goToNextQuestion(features));
    }, 150);
  });

  return {
    isCorrectAnswer,
    title,
    description,
    containerStyle,
    nextHandler,
  };
};
