import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import { useAppDispatch } from 'bootstrap/hooks';
import { goToNextQuestion } from 'bootstrap/onboarding/actions';
import { useSubmit } from 'hooks/useSubmit';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Info } from './Info';
import { useFeatures } from 'useFeatures';
import styles from './styles.module.scss';

export const useInfoComponentLogic = (question: Info) => {
  const dispatch = useAppDispatch();

  const { id, title, description } = question;
  const [containerStyle, setContainerStyle] = useState('');

  const features = useFeatures();

  useEffect(() => {
    setContainerStyle('enter');
    const timer = setTimeout(() => {
      document.body.classList.add(styles.bodyBackground);
    }, 250);
    return () => {
      document.body.classList.remove(styles.bodyBackground);
      clearTimeout(timer);
    };
  }, []);

  const nextHandler = useSubmit((e: SyntheticEvent) => {
    e.preventDefault();
    document.body.classList.remove(styles.bodyBackground);
    setContainerStyle('leave');
    setTimeout(() => {
      appAnalytics.trackEvent(new SaveAnswer(id));
      dispatch(goToNextQuestion(features));
    }, 150);
  });

  return {
    title,
    description,
    containerStyle,
    nextHandler,
  };
};
