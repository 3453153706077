import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { pushWithSearchQuery } from 'utils';
import { QuestionIds } from './data/constants/QuestionsIds';
import { getQuestionComponent } from './utils';
import { Question } from './components/question/Question';
import { appAnalytics } from 'analytics';
import { SubscriptionCancelScreenOpened } from './analytics';
import styles from './styles.module.scss';

type Params = {
  questionId: QuestionIds;
};

export const SubscriptionCancel = () => {
  const dispatch = useDispatch();

  const { questionId } = useParams<Params>();

  useEffect(() => {
    if (QuestionIds[questionId]) {
      appAnalytics.trackEvent(
        new SubscriptionCancelScreenOpened(
          // @ts-ignore
          `cancel${questionsMeta[questionId].id}`,
        ),
      );
    }
  }, [questionId]);

  if (!QuestionIds[questionId]) {
    dispatch(
      pushWithSearchQuery(`/subscription-cancel/${QuestionIds.Welcome}`),
    );
  }

  // @ts-ignore
  const question: Question = questionsMeta[questionId];
  const QuestionComponent = getQuestionComponent(question);

  return (
    <>
      <div className={styles.container}>
        {QuestionComponent && <QuestionComponent question={question} />}
      </div>
    </>
  );
};
