import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { Steps } from 'onboarding/data/constants/StepsLabels';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { QuestionTypes } from './question/Question';
import { getSteps } from './data/Steps/Steps';
import { useFeatures } from 'useFeatures';
import { getQuestionsMeta } from './data/meta';

type Props = { questionId: QuestionIds };

export const useStepperWrapperLogic = ({ questionId }: Props) => {
  const location = useLocation();
  const [stepName, setStepName] = useState<Steps>(Steps.AboutYou);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [stepsTotal, setStepsTotal] = useState<number>(0);
  const [currentSubstep, setCurrentSubstep] = useState<number>(0);
  const [substepsTotal, setSubstepsTotal] = useState<number>(0);
  const [questionType, setQuestionType] = useState<QuestionTypes>();

  const {
    isAdhdTest,
    isDefaultOnboarding,
    isEducationV1Quiz,
    isEducationV2Quiz,
    isEducationV3Quiz,
    isGadTest,
    isInsomniaV1Quiz,
    isInsomniaV2Quiz,
  } = useFeatures();

  const questionsMeta = getQuestionsMeta(
    isAdhdTest,
    isGadTest,
    isDefaultOnboarding,
  );

  const stepsData = getSteps({
    isAdhdTest,
    isDefaultOnboarding,
    isEducationV1Quiz,
    isEducationV2Quiz,
    isEducationV3Quiz,
    isGadTest,
    isInsomniaV1Quiz,
    isInsomniaV2Quiz,
  });

  useEffect(() => {
    setQuestionType(questionsMeta[questionId].type);
  }, [location]);

  useEffect(() => {
    const arrayedSteps = Array.from(stepsData.keys());
    stepsData.forEach((steps, name) => {
      if (steps.includes(questionId)) {
        setStepName(name);
        setCurrentStep(arrayedSteps.indexOf(name));
        setCurrentSubstep(steps.indexOf(questionId));
        setSubstepsTotal(steps.length);
        setStepsTotal(arrayedSteps.length);
      }
    });
  }, [questionId]);

  return {
    stepName,
    currentStep,
    stepsTotal,
    currentSubstep,
    substepsTotal,
    questionType,
  };
};
