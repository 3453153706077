import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { setAnswer } from 'bootstrap/onboarding/actions';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { StatementQuestion } from './StatementQuestion';
import {
  RELATE_SCALE_OPTIONS,
  Option,
  DEFAULT_OPTIONS,
  GAD_OPTIONS,
} from './StatementQuestion.constants';
import { useFeatures } from 'useFeatures';

export const useStatementQuestionLogic = (question: StatementQuestion) => {
  const dispatch = useAppDispatch();

  const { answers } = useAppSelector((state) => state.onboarding);

  const [activeOption, setActiveOption] = useState<string>('');
  const [containerStyle, setContainerStyle] = useState<string>('');
  const [isLeaving, setLeaving] = useState<boolean>(false);

  const features = useFeatures();

  const { isRelateScaleQuestion } = features;

  const options: Option[] = useMemo(() => {
    if (question.isGadStatementQuestion) {
      return GAD_OPTIONS;
    } else if (isRelateScaleQuestion) {
      return RELATE_SCALE_OPTIONS;
    }
    return DEFAULT_OPTIONS;
  }, [question, isRelateScaleQuestion]);

  useEffect(() => {
    const currentAnswer = answers[question.id];
    setContainerStyle('enter');
    setLeaving(false);
    if (currentAnswer && typeof currentAnswer === 'string') {
      setActiveOption(currentAnswer);
    } else {
      setActiveOption('');
    }
  }, [question]);

  const optionHandler = (option: Option) => (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isLeaving) {
      setActiveOption(option.id);
      setLeaving(true);
      setTimeout(() => {
        setContainerStyle('leave');
        setTimeout(() => {
          appAnalytics.trackEvent(new SaveAnswer(question.id, option.id));
          dispatch(setAnswer(question.id, option.id, features));
        }, 200);
      }, 100);
    }
  };

  return {
    isRelateScaleQuestion,
    activeOption,
    containerStyle,
    options,
    optionHandler,
  };
};
