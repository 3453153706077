import { useEffect } from 'react';
import { appAnalytics } from 'analytics';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { GiftProductSelected, GiftScreenOpened } from 'giftcards/analytics';
import { getGiftCardLabel } from 'giftcards/utils';
import { pushWithSearchQuery } from 'utils';
import { GiftCardTypes } from 'bootstrap/data/giftproduct/GiftCard.types';
import { setGiftCardType } from 'bootstrap/giftpurchase/actions/giftCardType';
import { GiftCardProducts } from 'bootstrap/data/giftproduct/GiftCardProducts';
import { setCountryCode, setCountryCurrency } from 'bootstrap/user/actions';
import { useFeatures } from 'useFeatures';

type Props = {
  products: GiftCardProducts;
  giftCardType: GiftCardTypes;
};

export const usePlanContentLogic = ({ products, giftCardType }: Props) => {
  const dispatch = useAppDispatch();
  const { isLocalCurrencyEnabled } = useFeatures();

  const { countryCode, currency } = useAppSelector((state) => state.user);
  const giftCardLabel = getGiftCardLabel(giftCardType);

  useEffect(() => {
    appAnalytics.trackEvent(new GiftScreenOpened('giftWelcome'));

    dispatch(setCountryCode(countryCode || ''));

    if (isLocalCurrencyEnabled) {
      dispatch(setCountryCurrency(currency || ''));
    }
  }, []);

  const onContinue = () => {
    appAnalytics.trackEvent(
      new GiftProductSelected(products[giftCardType].code),
    );
    dispatch(pushWithSearchQuery('/gift/setup'));
  };

  const onCardSelect = (type: GiftCardTypes) => {
    dispatch(setGiftCardType(type));
  };

  return {
    giftCardLabel,
    onContinue,
    onCardSelect,
  };
};
