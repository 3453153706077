import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { RefObject, SyntheticEvent, useEffect, useState } from 'react';
import { CustomQuestion } from 'onboarding/question/Question';
import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import { goToNextQuestion } from 'bootstrap/onboarding/actions';
import { useSubmit } from 'hooks/useSubmit';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { useFeatures } from 'useFeatures';

type Props = {
  question: CustomQuestion;
  imageRef: RefObject<HTMLImageElement>;
};

export const useMotivationComponentLogic = ({ question, imageRef }: Props) => {
  const dispatch = useAppDispatch();
  const gender = useAppSelector(
    (state) => state.onboarding.answers[QuestionIds.AboutYouGender],
  );

  const [containerStyle, setContainerStyle] = useState<string>('');
  const [imageStyle, setImageStyle] = useState<string>('');

  const features = useFeatures();

  useEffect(() => {
    setContainerStyle('enter');
    setImageStyle('');
    if (imageRef.current) {
      setImageStyle('transition');
      setTimeout(() => {
        setImageStyle('visible');
      }, 200);
    }
  }, [question]);

  const nextButtonHandler = useSubmit((e: SyntheticEvent) => {
    e.preventDefault();
    appAnalytics.trackEvent(new SaveAnswer(question.id));
    setContainerStyle('leave');
    setTimeout(() => {
      dispatch(goToNextQuestion(features));
    }, 250);
  });

  const getGender = (): string => {
    if (gender === 'male' || gender === 'female') {
      return gender as string;
    }
    return 'female';
  };

  return {
    containerStyle,
    imageStyle,
    gender: getGender(),
    nextButtonHandler,
  };
};
