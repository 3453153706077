import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import MorningBg from 'assets/meqresults/morning_bg.png';
import EveningBg from 'assets/meqresults/evening_bg.png';
import NeutralBg from 'assets/meqresults/neutral_bg.png';
import MorningSticker from 'assets/meqresults/morning_sticker.png';
import EveningSticker from 'assets/meqresults/evening_sticker.png';
import NeutralSticker from 'assets/meqresults/neutral_sticker.png';

import MorningAnimalSticker from 'assets/meqresults/animals/lion.png';
import EveningAnimalSticker from 'assets/meqresults/animals/wolf.png';
import NeutralAnimalSticker from 'assets/meqresults/animals/bear.png';
import { useFeatures } from 'useFeatures';

export enum CircadianTypes {
  Morning = 'Morning',
  Neutral = 'Neutral',
  Evening = 'Evening',
}

export const MappedRmeqAnswersWeight: Record<string, number> = {
  definitely_evening: 0,
  '11_12pm': 1,
  very_tired: 1,
  '2_3am': 1,
  '10pm_5am': 1,
  '9:45_11am': 2,
  fairly_tired: 2,
  '12:45_2am': 2,
  '5_10pm': 2,
  rather_evening: 2,
  '7:45_9:45am': 3,
  fairly_refreshed: 3,
  '10:15_12:45am': 3,
  '10am_5pm': 3,
  '6:30_7:45am': 4,
  very_refreshed: 4,
  '9_10:15pm': 4,
  '8_10am': 4,
  rather_morning: 4,
  '5_6:30am': 5,
  '8_9pm': 5,
  '5_8am': 5,
  definitely_morning: 6,
};

export const RmeqQuestionIds: QuestionIds[] = [
  QuestionIds.RMeqQ1,
  QuestionIds.RMeqQ2,
  QuestionIds.RMeqQ3,
  QuestionIds.RMeqQ4,
  QuestionIds.RMeqQ5,
];

export interface RmeqResult {
  title: string;
  description: string;
  bedTime: string;
  wakeUpTime: string;
  background: string;
  stickerImg: string;
}

export const useRmeqResultData = (): Record<CircadianTypes, RmeqResult> => {
  const { isMeqResultAnimalFlow } = useFeatures();

  return {
    [CircadianTypes.Morning]: {
      title: 'RmeqResultMorningTitle',
      description: 'RmeqResultMorningDescription',
      bedTime: '21:30-22:45',
      wakeUpTime: '5:00-6:30',
      background: MorningBg,
      stickerImg: isMeqResultAnimalFlow ? MorningAnimalSticker : MorningSticker,
    },
    [CircadianTypes.Neutral]: {
      title: 'RmeqResultNeutralTitle',
      description: 'RmeqResultNeutralDescription',
      bedTime: '22:45-00:45',
      wakeUpTime: '6:30-8:30',
      background: NeutralBg,
      stickerImg: isMeqResultAnimalFlow ? NeutralAnimalSticker : NeutralSticker,
    },
    [CircadianTypes.Evening]: {
      title: 'RmeqResultEveningTitle',
      description: 'RmeqResultEveningDescription',
      bedTime: '00:45-2:00',
      wakeUpTime: '8:30-10:00',
      background: EveningBg,
      stickerImg: isMeqResultAnimalFlow ? EveningAnimalSticker : EveningSticker,
    },
  };
};
