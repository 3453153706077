import React from 'react';
import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import ActiveUsersMapGraph from 'assets/onboarding/graphs/active_users_map_graph.svg';
import ActiveUsersMapGraphV2 from 'assets/onboarding/graphs/active_users_map_graph_v2.svg';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { goToNextQuestion } from 'bootstrap/onboarding/actions';
import { useProgress } from 'hooks/useProgress';
import { useProgressText } from 'hooks/useProgressText';
import { useSubmit } from 'hooks/useSubmit';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { CustomQuestion } from 'onboarding/question/Question';
import { placeholderFormatter } from 'onboarding/data/constants/Placeholders';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { useTranslation } from 'react-i18next';
import { ProgressButton } from 'onboarding/components/progressbutton/ProgressButton';
import { useFeatures } from 'useFeatures';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<CustomQuestion>;

const DURATION = 10000;
const DELAY = 0;
const STEPS = [0.34, 0.69, 0.91, 1];

export const AboutYouProof: React.FC<Props> = ({ question }: Props) => {
  const dispatch = useAppDispatch();

  const features = useFeatures();

  const { isAdhdTest } = features;
  const { answers } = useAppSelector((state) => state.onboarding);
  const { t } = useTranslation();
  const progress = useProgress(DURATION, DELAY, STEPS);
  const firstProgressText = useProgressText(
    'CONNECTING TO DATABASE',
    DURATION + DELAY * (STEPS.length - 1),
  );
  const secondProgressText = useProgressText(
    'PRELIMINARY PROCESSING',
    DURATION + DELAY * (STEPS.length - 1),
  );

  const nextButtonHandler = useSubmit(() => {
    appAnalytics.trackEvent(new SaveAnswer(question.id));
    dispatch(goToNextQuestion(features));
  });

  const gender = placeholderFormatter(
    answers,
    QuestionIds.AboutYouGender,
  ).toLocaleLowerCase();

  const age = placeholderFormatter(answers, QuestionIds.AboutYouAge);

  return (
    <div className={`${styles.container} maxWidth`}>
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <h3 className={styles.title}>{t('AboutYouProof1Title')}</h3>
          <img
            src={isAdhdTest ? ActiveUsersMapGraphV2 : ActiveUsersMapGraph}
            alt=""
          />
          <h3 className={styles.graphDescription}>
            {isAdhdTest
              ? t('AboutYouProof2Description', { gender, age })
              : t('AboutYouProof1Description', { gender, age })}
          </h3>
        </div>
        <div className={styles.bottomGradient} />
      </div>
      <div className={styles.button}>
        <div className={styles.progressText}>
          {progress < 0.35 ? firstProgressText : secondProgressText}
        </div>
        <ProgressButton
          progress={progress}
          finishText={t('Next')}
          onClick={nextButtonHandler}
        />
      </div>
    </div>
  );
};
