import { Product } from 'bootstrap/data/product/Product';
import Divider from 'components/divider/Divider';
import { useTranslation } from 'react-i18next';
import { isQuickPurchaseFlow } from 'useFeatures';
import styles from './styles.module.scss';

type Props = {
  product: Product;
  currencyCode?: string;
};

export const PriceAfterTrialInfo = ({
  product,
  currencyCode,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const productPrice = product.getFormattedPrice(currencyCode);
  const productRenewsPrice = product.getFormattedRenewsPrice(currencyCode);

  return (
    <>
      {!!product.trialLength ? (
        <div className={styles.totalHeader}>{t('paymentAfterTrialPrice')}</div>
      ) : (
        <div className={styles.totalHeader}>{t('paymentTotalPrice')}</div>
      )}

      <Divider isDividerWithNoMargin />

      <div className={styles.total}>
        {t('paymentPlanDuration', {
          duration: product.getTerm(t).replace(' ', '-'),
        })}
        <span className={styles.bolder}>
          {t('paymentDuration', {
            price: product.getFormattedWeeklyPrice(currencyCode),
          })}
        </span>
      </div>
      <div className={`${styles.total} ${styles.top8}`}>
        <span className={styles.purpleBadge}>
          {product.discount?.value || 30}% OFF
        </span>
        <span className={styles.grey}>
          {t('paymentBilledAt')} {productPrice}
        </span>
      </div>
      {isQuickPurchaseFlow() && product.rampIntervals.length > 0 && (
        <div className={`${styles.total} ${styles.renews}`}>
          <span className={styles.grey}>Renews at {productRenewsPrice}</span>
        </div>
      )}
    </>
  );
};
