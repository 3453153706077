export enum FeatureTypes {
  CONGRATS = 'congrats',
  SKIP_TRIAL = 'st',
  PAYMENT_FORM = 'pf',
  PRODUCT = 'pr',
  PLAN = 'plan',
  SPECIAL_FLOW = 'sf',
  FACEBOOK_REVIEWS = 'reviews',
  GAD = 'gad',
  FAQ = 'faq',
  FREE_PAID = 'freeVsPaid',
  PERSONALIZATION = 'personalization',
  PERSONALIZATION_LOADER = 'opl_estimate',
  LOCAL_CURRENCY = 'loc',
  PRESALE_OFFER = 'ps_offer',
  BLITZ = 'blitz',
  SLEEP_ACTIVITY_LOADER_V2 = 'loader_v2',
  PRICE_BUTTON = 'priceButton',
  FEATURED_IN = 'featuredIn',
  TRIAL_EXPLAINER = 'trex',
  EVENT_POLICIES = 'ep',
  WELCOME_LOADER = 'welcome_loader',
  PREPLAN = 'preplan',
  MEQ_RESULTS = 'results',
  ANIMATION_SPEED_INCREASE = 'trans_speed_increase',
  WORKBOOK = 'workbook',
  VENMO = 'venmo',
  PREVENT_BACK = 'pb',
  PERSONALIZATIOND3 = 'personalizationD3',
  RELATE_SCALE_QUESTION = 'relsc',
  PAYMENT = 'payment',
  EDU = 'edu',
  ISI = 'isi',
  FLOW = 'flow',
}
