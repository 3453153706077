import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import { useAppDispatch } from 'bootstrap/hooks';
import { goToNextQuestion } from 'bootstrap/onboarding/actions';
import { useSubmit } from 'hooks/useSubmit';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { CustomQuestion } from 'onboarding/question/Question';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useFeatures } from 'useFeatures';

export const useYoYoGraphLogic = (question: CustomQuestion) => {
  const dispatch = useAppDispatch();
  const [containerStyle, setContainerStyle] = useState<string>('');

  const features = useFeatures();

  useEffect(() => {
    setContainerStyle('enter');
  }, [question]);

  const nextButtonHandler = useSubmit((e: SyntheticEvent) => {
    e.preventDefault();
    setContainerStyle('leave');
    setTimeout(() => {
      appAnalytics.trackEvent(
        new SaveAnswer(QuestionIds.SleepAndActivityProof1),
      );
      dispatch(goToNextQuestion(features));
    }, 250);
  });

  return {
    containerStyle,
    nextButtonHandler,
  };
};
