import { QuestionIds } from '../constants/QuestionIds';
import { DefaultOnboradingQuestionsOrder } from './DefaultOnboardingOrder';
import { BasicQuestionsOrder } from './BasicOrder';
import { GadTestQuestionsOrder } from './GadTestOrder';
import { EducationQuizV1Order } from './EducationQuizV1Order';
import { EducationQuizV2Order } from './EducationQuizV2Order';
import { EducationQuizV3Order } from './EducationQuizV3Order';
import { InsomniaQuizV1Order } from './InsomniaQuizV1Order';
import { InsomniaQuizV2Order } from './insomniaQuizV2Order';
import { AdhdQuestionsOrder } from './AdhdFlowOrder';

interface GetQuestionsOrderParams {
  isAdhdTest: boolean;
  isDefaultOnboarding: boolean;
  isEducationV1Quiz: boolean;
  isEducationV2Quiz: boolean;
  isEducationV3Quiz: boolean;
  isGadTest: boolean;
  isInsomniaV1Quiz: boolean;
  isInsomniaV2Quiz: boolean;
}

export const getQuestionsOrder = ({
  isAdhdTest,
  isDefaultOnboarding,
  isEducationV1Quiz,
  isEducationV2Quiz,
  isEducationV3Quiz,
  isGadTest,
  isInsomniaV1Quiz,
  isInsomniaV2Quiz,
}: GetQuestionsOrderParams): QuestionIds[] => {
  switch (true) {
    case isDefaultOnboarding:
      return DefaultOnboradingQuestionsOrder;
    case isGadTest:
      return GadTestQuestionsOrder;
    case isEducationV1Quiz:
      return EducationQuizV1Order;
    case isEducationV2Quiz:
      return EducationQuizV2Order;
    case isEducationV3Quiz:
      return EducationQuizV3Order;
    case isInsomniaV1Quiz:
      return InsomniaQuizV1Order;
    case isInsomniaV2Quiz:
      return InsomniaQuizV2Order;
    case isAdhdTest:
      return AdhdQuestionsOrder;
    default:
      return BasicQuestionsOrder;
  }
};
