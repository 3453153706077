import Divider from 'components/divider/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useFeatures } from 'useFeatures';

type Props = {
  totalPrice: string;
  totalSavings: string;
  priceWithTrial: string;
  priceWithoutTrial: string;
  defaultProductPrice: string;
  duration: string;
  trialFee: string;
  discountAmount: string;
  discountPercent: number;
  isDiscountApplied: boolean | undefined;
};

export const PlanContent = ({
  discountAmount,
  discountPercent,
  totalPrice,
  totalSavings,
  priceWithTrial,
  defaultProductPrice,
  duration,
  trialFee,
  isDiscountApplied,
}: Props) => {
  const { t } = useTranslation();

  const { isSkipTrialV3, isSkipTrialWithoutRefund } = useFeatures();

  return (
    <div
      className={`${styles.planContent} ${isSkipTrialV3 ? styles.st_v3 : ''}`}
    >
      <div className={styles.title}>
        {t('skipTrialTitle')}

        {isSkipTrialV3 && !isDiscountApplied && (
          <span>{t('paymentTotalPriceWithoutColon')}</span>
        )}
      </div>

      <Divider isDividerWithNoMargin />

      <div className={`${styles.field} ${styles.plan}`}>
        <div
          className={`
            ${styles.label} 
            ${!isDiscountApplied ? styles.normal : ''}
          `}
        >
          {t('skipTrialLabel1', {
            duration: duration.replace(' ', '-'),
          })}
        </div>
        <span>
          {isSkipTrialWithoutRefund ? defaultProductPrice : priceWithTrial}
        </span>
      </div>

      {isDiscountApplied && <Divider isDividerWithNoMargin />}

      <div
        className={`
            ${styles.field} 
            ${styles.skipTrial} 
            ${!isDiscountApplied ? styles.accent : ''}
        `}
      >
        <div className={styles.label}>{t('skipTrialLabel2')}</div>

        <div className={styles.prices}>
          <span
            className={`
              ${styles.price} 
              ${!isDiscountApplied ? styles.green : ''}
            `}
          >
            -{trialFee}
          </span>
          {isSkipTrialV3 && !isDiscountApplied && (
            <span className={styles.priceLabel}>{totalPrice}</span>
          )}
        </div>
      </div>

      {isDiscountApplied && (
        <>
          <div className={`${styles.field} ${styles.discount}`}>
            <span className={styles.purpleBadge}>{discountPercent}% OFF</span>
            <span>-{discountAmount}</span>
          </div>

          <div className={`${styles.field} ${styles.totalSave}`}>
            <div className={styles.label}>{t('skipTrialLabel3')}</div>
            <span>{totalSavings}</span>
          </div>
        </>
      )}

      {(!isSkipTrialV3 || isDiscountApplied) && (
        <>
          <Divider isDividerWithNoMargin />
          <div className={`${styles.field} ${styles.total}`}>
            <div className={styles.label}>{t('paymentTotalPrice')}</div>
            <span>{totalPrice}</span>
          </div>
        </>
      )}
    </div>
  );
};
