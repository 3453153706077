import React, { useRef } from 'react';
import { useAppDispatch } from 'bootstrap/hooks';
import { Footer } from 'components/footer/Footer';
import { pushWithSearchQuery } from 'utils';
import { Information } from 'components/information/Information';
import { Recurly } from 'subscription/recurly/Recurly';
import { TrialOffer } from 'subscription/recurly/components/trialoffer/TrialOffer';
import { StickyHeader } from 'subscription/components/stickyheader/StickyHeader';
import { Products } from 'bootstrap/data/product/Product.types';
import { ReasonToUseTemplate } from 'subscription/components/reasontemplate/ReasonToUseTemplate';
import { Header } from 'subscription/components/header/Header';
import Divider from 'components/divider/Divider';
import { PaymentFormTemplate } from './components/paymentformtemplate/PaymentFormTemplate';
import { usePaymentLogic } from './Payment.hook';
import { Reviews } from 'subscription/components/reviews/Reviews';
import { FacebookReviews } from 'subscription/components/reviews/FacebookReviews';
import { PaymentChecklist } from './components/paymentchecklist/PaymentChecklist';
import PromoAlert from './components/promoalert/PromoAlert';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useFeatures } from 'useFeatures';
import styles from './styles.module.scss';

type PaymentProps = {
  products: Products;
};

export const Payment = ({ products }: PaymentProps) => {
  const dispatch = useAppDispatch();
  const startDate = Date.now();
  const paymentFormRef = useRef<HTMLDivElement>(null);

  const { isFacebookReviews } = useFeatures();

  const {
    authToken,
    product,
    expanded,
    isTrialOfferShown,
    isShownPromoAlert,
    currencyCode,
    showPromoAlert,
    setNewProduct,
    handleChange,
    scrollToPaymentForm,
  } = usePaymentLogic({ products, paymentFormRef });

  if (!authToken) {
    dispatch(pushWithSearchQuery('/create-account'));
    return null;
  }

  if (isTrialOfferShown) {
    return <TrialOffer defaultProduct={product} isTrialOfferVisible={true} />;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <div className={styles.payment}>
        <PromoAlert
          products={products}
          isShownPromoAlert={isShownPromoAlert}
          showPromoAlert={showPromoAlert}
          setNewProduct={setNewProduct}
        />

        <StickyHeader startDate={startDate} trialLength={product.trialLength} />

        <div
          className={`
          ${styles.paymentContainer} 
          ${expanded ? styles.bottom90 : ''}
        `}
        >
          <Header />

          <ReasonToUseTemplate
            className={styles.reasonTemplate}
            withDivider={false}
          />

          <Divider />

          <PaymentFormTemplate
            products={products}
            product={product}
            expanded={expanded}
            currencyCode={currencyCode}
            handleChange={handleChange}
            scrollToPaymentForm={scrollToPaymentForm}
          />

          {!expanded && <Divider />}

          {isFacebookReviews ? <FacebookReviews /> : <Reviews />}

          {!isFacebookReviews && !expanded && <Divider />}

          {expanded && (
            <div ref={paymentFormRef}>
              {!isFacebookReviews && <Divider />}

              <Recurly product={product} />

              <PaymentChecklist
                products={products}
                product={product}
                currencyCode={currencyCode}
              />

              <Divider />
            </div>
          )}
          <Information />
          <Footer className={styles.footer} />
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};
