import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import { useAppDispatch } from 'bootstrap/hooks';
import { goToNextQuestion } from 'bootstrap/onboarding/actions';
import { useSubmit } from 'hooks/useSubmit';
import { CustomQuestion } from '../Question';
import { useFeatures } from 'useFeatures';

export const usePersonalizationLogic = (question: CustomQuestion) => {
  const dispatch = useAppDispatch();

  const features = useFeatures();

  const nextButtonHandler = useSubmit(() => {
    setTimeout(() => {
      appAnalytics.trackEvent(new SaveAnswer(question.id));
      dispatch(goToNextQuestion(features));
    }, 250);
  });

  return {
    nextButtonHandler,
  };
};
