import React from 'react';
import BingeWatchingGraph from 'assets/onboarding/graphs/bingewatching_graph.svg';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useFeatures } from 'useFeatures';

export const Header = () => {
  const { t } = useTranslation();

  const { isAdhdTest } = useFeatures();

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <h3>
          {isAdhdTest
            ? t('paymentPageHeaderTitlev3')
            : t('paymentPageHeaderTitlev2')}
        </h3>
        {isAdhdTest
          ? t('paymentPageHeaderSubTitlev2')
          : t('paymentPageHeaderSubTitle')}
      </div>

      <img src={BingeWatchingGraph} alt="Graph" />

      <div className={styles.graphDescription}>
        {t('paymentPageHeaderDescription')}
      </div>
    </div>
  );
};
