import React from 'react';
import { ReactComponent as Logo } from 'assets/logo_loona.svg';
import { Product } from 'bootstrap/data/product/Product';
import { DiscountPopup } from './components/popup/DiscountPopup';
import Alert from 'components/error/Error';
import { Note } from './components/note/Note';
import { TrialOfferInfo } from './components/trialofferinfo/TrialOfferInfo';
import { GraphsContainer } from './components/graphs/GraphsContainer';
import { useTrialOfferLogic } from './TrialOffer.hook';
import { PlanContent } from './components/plancontent/PlanContent';
import { FooterButtonsContainer } from './components/footercontainer/ButtonsContainer';
import { useFeatures } from 'useFeatures';
import styles from './styles.module.scss';

type Props = {
  defaultProduct: Product;
  isTrialOfferVisible: boolean;
};

const improvementPercent = 79;

export const TrialOffer = ({ defaultProduct, isTrialOfferVisible }: Props) => {
  const {
    isLoading,
    isErrorVisible,
    errorMessage,
    totalPrice,
    term,
    trialFee,
    totalSavings,
    priceWithTrial,
    defaultProductPrice,
    priceWithoutTrial,
    discountPercent,
    discountAmount,
    duration,
    isDiscountPopupShown,
    isDiscountApplied,
    handleContinueClick,
    handleContinueWithTrialClick,
    handleSkipTrialClick,
    handleErrorVisible,
  } = useTrialOfferLogic({
    defaultProduct,
    isTrialOfferVisible,
  });

  const { isSkipTrialV3 } = useFeatures();

  return (
    <div
      className={`
        ${styles.trialOffer} 
        ${isTrialOfferVisible ? '' : styles.hidden}
      `}
    >
      <Alert
        visible={isErrorVisible}
        error={errorMessage}
        onClose={handleErrorVisible}
      />
      <div className={styles.container}>
        <Logo className={styles.logo} />

        <TrialOfferInfo improvementPercent={improvementPercent} />

        <GraphsContainer improvementPercent={improvementPercent} />

        <PlanContent
          discountAmount={discountAmount}
          discountPercent={discountPercent}
          defaultProductPrice={defaultProductPrice}
          totalPrice={totalPrice}
          totalSavings={totalSavings}
          priceWithTrial={priceWithTrial}
          priceWithoutTrial={priceWithoutTrial}
          duration={duration}
          trialFee={trialFee}
          isDiscountApplied={isDiscountApplied}
        />

        {isSkipTrialV3 && (
          <FooterButtonsContainer
            isLoading={isLoading}
            handleSkipTrialClick={handleSkipTrialClick}
            handleContinueWithTrialClick={handleContinueWithTrialClick}
          />
        )}

        <Note
          term={term}
          isDiscountApplied={isDiscountApplied}
          totalPrice={totalPrice}
          defaultProductPrice={defaultProductPrice}
        />

        {!isSkipTrialV3 && (
          <FooterButtonsContainer
            isLoading={isLoading}
            handleSkipTrialClick={handleSkipTrialClick}
            handleContinueWithTrialClick={handleContinueWithTrialClick}
          />
        )}

        {isDiscountPopupShown && (
          <DiscountPopup
            discountPercent={discountPercent}
            handleContinueClick={handleContinueClick}
          />
        )}
      </div>
    </div>
  );
};
