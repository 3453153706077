import React, { SyntheticEvent } from 'react';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useFeatures } from 'useFeatures';
import styles from './styles.module.scss';

type Props = {
  isLoading: boolean;
  handleSkipTrialClick: () => void;
  handleContinueWithTrialClick: (e: SyntheticEvent) => void;
};

export const FooterButtonsContainer = ({
  isLoading,
  handleSkipTrialClick,
  handleContinueWithTrialClick,
}: Props) => {
  const { t } = useTranslation();

  const { isSkipTrialV3 } = useFeatures();

  return (
    <>
      <LoadingButton
        className={styles.loadingButton}
        isLoading={isLoading}
        label={t('trialOfferSkipTrialButton')}
        onClick={handleSkipTrialClick}
      />

      <button
        className={`${styles.secondary} ${isSkipTrialV3 ? styles.st_v3 : ''}`}
        onClick={handleContinueWithTrialClick}
      >
        {t('trialOfferContinueButton')}
      </button>
    </>
  );
};
