import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { OptionalQuestion } from 'onboarding/question/options/OptionQuestion';
import { pushWithSearchQuery } from 'utils';
import { AppThunk } from 'bootstrap/types';
import { DEFAULT_ANSWERS } from 'onboarding/data/constants/DefaultAnswers';
import { Answers } from '../state';
import { OnboardingActionTypes } from './types';
import { AppDispatch } from 'index';
import { GadQuestionIds } from 'onboarding/question/gadresults/GadResult.constants';
import { QuestionTypes } from 'onboarding/question/Question';
import { RmeqQuestionIds } from 'onboarding/question/meqresults/MeqResults.constants';
import { InsomniaQuestionIds } from 'onboarding/question/insomniaresults/InsomniaResults.constants';
import { QUESTIONS_META } from 'cancelsubscription/data/meta';
import { getQuestionsOrder } from 'onboarding/data/QuestionsOrder/QuestionsOrder';
import { Features } from 'useFeatures';

export const SET_ANSWER = 'SET_ANSWER';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';

export const setAnswer =
  (
    question: QuestionIds,
    answer: string | string[],
    features: Features,
    navigateNext = true,
  ): AppThunk<OnboardingActionTypes> =>
  (dispatch: AppDispatch, _) => {
    dispatch({
      type: SET_ANSWER,
      payload: {
        question,
        answer,
      },
    });
    navigateNext && dispatch(goToNextQuestion(features));
  };

export const hasOption = (
  answers: Answers,
  question: OptionalQuestion,
): boolean => {
  const answer =
    answers[question.sourceQuestionId] ||
    DEFAULT_ANSWERS[question.sourceQuestionId];
  return typeof answer === 'string' && !!question.options[answer];
};

export const hasAllRmeqTestAnswers = (answers: Answers) => {
  return RmeqQuestionIds.every(
    (questionId) =>
      Object.keys(answers).includes(questionId) && answers[questionId],
  );
};

export const hasAllGadTestAnswers = (answers: Answers) => {
  return GadQuestionIds.every(
    (questionId) =>
      Object.keys(answers).includes(questionId) && answers[questionId],
  );
};

export const hasAllInsomniaTestAnswers = (answers: Answers) => {
  return InsomniaQuestionIds.every(
    (questionId) =>
      Object.keys(answers).includes(questionId) && answers[questionId],
  );
};

export const goToNextQuestion =
  (features: Features): AppThunk<OnboardingActionTypes> =>
  (dispatch: AppDispatch, getState) => {
    const currentQuestionId = window.location.pathname.replace(
      '/onboarding/',
      '',
    );
    const questionsOrder = getQuestionsOrder(features);

    let currentQuestionIndex = questionsOrder.indexOf(
      currentQuestionId as QuestionIds,
    );
    const { answers } = getState().onboarding;

    // @ts-ignore
    const nextQuestionMeta =
      // @ts-ignore
      QUESTIONS_META[questionsOrder[currentQuestionIndex + 1]];
    if (nextQuestionMeta && nextQuestionMeta.type === QuestionTypes.Optional) {
      if (!hasOption(answers, nextQuestionMeta as OptionalQuestion)) {
        currentQuestionIndex += 1;
      }
    }

    if (nextQuestionMeta && nextQuestionMeta.id) {
      if (
        nextQuestionMeta.id === QuestionIds.IsiD1 &&
        !hasAllInsomniaTestAnswers(answers)
      ) {
        currentQuestionIndex += 1;
      }
      if (
        nextQuestionMeta.id === QuestionIds.MeqResults &&
        !hasAllRmeqTestAnswers(answers)
      ) {
        currentQuestionIndex += 1;
      }
    }
    if (questionsOrder.length - 1 > currentQuestionIndex) {
      const nextQuestion = questionsOrder[currentQuestionIndex + 1];

      dispatch({
        type: SET_CURRENT_QUESTION,
        question: questionsOrder[currentQuestionIndex + 1],
      });
      dispatch(pushWithSearchQuery(`/onboarding/${nextQuestion}`));
    } else {
      dispatch(pushWithSearchQuery('/create-account'));
    }
  };
