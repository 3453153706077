import React from 'react';
import { CookieConsent } from 'onboarding/components/cookieconsent/CookieConsent';
import { useTranslation } from 'react-i18next';
import { Footer } from 'welcome/components/footer/Footer';
import { useRmeqWelcomeLogic } from './WelcomeRmeq.hook';
import { useFeatures } from 'useFeatures';
import { ProgressButton } from '../../onboarding/components/progressbutton/ProgressButton';
import styles from './styles.module.scss';

const RmeqWelcome = () => {
  const { t } = useTranslation();

  const { isWelcomeLoaderFlow } = useFeatures();

  const { progress, title, subtitle, imageUrl, handleTakeTest } =
    useRmeqWelcomeLogic();

  return (
    <div className={styles.wrapper}>
      {imageUrl && (
        <img src={imageUrl} srcSet={imageUrl} className={styles.image} alt="" />
      )}

      <div className={styles.content}>
        <h1 className={styles.title}>
          {title ? (
            title
          ) : (
            <>
              {t('welcomeRmeqTitleLine1')}
              <span className={styles.accent}>
                {t('welcomeRmeqTitleLine2')}
              </span>
            </>
          )}
        </h1>

        <h3 className={styles.subtitle}>
          {subtitle ? (
            subtitle
          ) : (
            <>
              {t('welcomeRmeqSubtitleLine1')}
              <span className={styles.accent}>
                {t('welcomeRmeqSubtitleLine2')}
              </span>
              {t('welcomeRmeqSubtitleLine3')}
              <span className={styles.accent}>
                {t('welcomeRmeqSubtitleLine4')}
              </span>
              {t('welcomeRmeqSubtitleLine5')}
            </>
          )}
        </h3>

        {isWelcomeLoaderFlow ? (
          <ProgressButton
            className={styles.progressButton}
            progress={progress}
            startText="Loading your test"
            finishText=""
            isAutoContinue={true}
            onClick={handleTakeTest}
          />
        ) : (
          <button className={styles.button} onClick={handleTakeTest}>
            {t('welcomeRmeqAndGadContinueButton')}
          </button>
        )}

        <Footer />
      </div>
      <CookieConsent />
    </div>
  );
};

export default RmeqWelcome;
