import { appAnalytics } from 'analytics';
import { Amplitude } from 'analytics/Amplitude';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { goToNextQuestion } from 'bootstrap/subscriptioncancel/actions/answer';
import {
  cancelSubscriptionPlan,
  setSubscriptionExpiredDate,
  setSubscriptionStatus,
} from 'bootstrap/user/actions';
import { ContinueSubscriptionCancel } from 'cancelsubscription/analytics';
import { QuestionIds } from 'cancelsubscription/data/constants/QuestionsIds';
import { push } from 'connected-react-router';
import { isShortWebCancelFlow, useFeatures } from 'useFeatures';
import { useEffect, useState } from 'react';
import { getOneLink, getQueryVariable } from 'utils';

export const useWelcomeLogic = () => {
  const dispatch = useAppDispatch();
  const { subscriptionEventStatus } = useAppSelector((state) => state.user);

  const features = useFeatures();

  const [error, setError] = useState('');

  useEffect(() => {
    const idx = getQueryVariable('idx');

    if (idx) {
      Amplitude.setUserId(idx);
    }

    dispatch(setSubscriptionExpiredDate());
    dispatch(setSubscriptionStatus());
  }, []);

  const onKeepBenefitsHandler = () => {
    window.location.href = getOneLink({
      mediaSource: 'web_cancel',
      campaign: 'benefints',
      experience: 'custom',
    });
  };

  const onCancelSubscriptionHandler = () => {
    if (
      isShortWebCancelFlow() ||
      subscriptionEventStatus === 'CANCELLED_TRIAL' ||
      subscriptionEventStatus === 'CANCELED_SUBSCRIPTION_NOTIFICATION'
    ) {
      dispatch(cancelSubscriptionPlan())
        .then(() => {
          appAnalytics.trackEvent(new ContinueSubscriptionCancel());
          dispatch(push(QuestionIds.FinishUserLost));
        })
        .catch((error) => setError(error));
    } else {
      dispatch(goToNextQuestion(features));
    }
  };

  return {
    error,
    setError,
    onKeepBenefitsHandler,
    onCancelSubscriptionHandler,
  };
};
