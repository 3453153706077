import React from 'react';
import { StatementQuestion } from './StatementQuestion';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/spacer/Spacer';
import { useStatementQuestionLogic } from './StatementQuestion.hook';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<StatementQuestion>;

export const StatementQuestionComponent: React.FC<Props> = ({
  question,
}: Props) => {
  const { t } = useTranslation();

  const {
    isRelateScaleQuestion,
    activeOption,
    options,
    containerStyle,
    optionHandler,
  } = useStatementQuestionLogic(question);

  return (
    <div
      className={`
        ${styles.container}
        ${styles[containerStyle]}   
        ${styles.speed}  
        maxWidth
      `}
    >
      <h3 className={styles.title}>{t(question.title)}</h3>
      <h2 className={styles.statement}>{t(question.statement)}</h2>
      <div
        className={`${styles.options} ${
          isRelateScaleQuestion ? styles.scale : ''
        }`}
      >
        {options.map((option) => (
          <button
            key={option.id}
            className={`
              ${styles.option} 
              ${activeOption === option.id ? styles.active : ''}
            `}
            onClick={optionHandler(option)}
          >
            {isRelateScaleQuestion ? option.text : t(option.id)}
          </button>
        ))}
      </div>
      {isRelateScaleQuestion && (
        <div className={styles.note}>
          <p>Strongly disagree</p>
          <p>Strongly agree</p>
        </div>
      )}
      <Spacer />
    </div>
  );
};
