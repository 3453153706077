import { GrowthBook } from '@growthbook/growthbook';
import { appAnalytics } from 'analytics';
import { ExperimentStarted } from 'analytics/AllEvents';

const clientKey = process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY;

if (!clientKey) {
  console.warn(
    'GrowthBook client key is missing. Check your environment variables.',
  );
}

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: clientKey,
  enableDevMode: true,
  trackingCallback: (experiment: any, result: any) => {
    appAnalytics.trackEvent(new ExperimentStarted(experiment.key, result.key));
  },
});
