import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { setAnswer } from 'bootstrap/onboarding/actions';
import { SyntheticEvent, useEffect, useState } from 'react';
import { ImageQuestion } from './ImageQuestion';
import { Option } from './ImageQuestionComponent';
import { useFeatures } from 'useFeatures';

export const useImageQuestionLogic = (question: ImageQuestion) => {
  const dispatch = useAppDispatch();
  const { answers } = useAppSelector((state) => state.onboarding);

  const features = useFeatures();

  const [activeOption, setActiveOption] = useState<string>('');
  const [containerStyle, setContainerStyle] = useState('');
  const [isLeaving, setLeaving] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const currentAnswer = answers[question.id];
    setLeaving(false);
    if (currentAnswer && typeof currentAnswer === 'string') {
      setActiveOption(currentAnswer);
    } else {
      setActiveOption('');
    }

    if (question.url) {
      setImageUrl(question.url);
    } else {
      setImageUrl(
        require(`../../../assets/onboarding/artworks/${question.imageId}.jpg`),
      );
    }
  }, [question]);

  const revealImage = () => {
    if (containerStyle !== 'enter') {
      setContainerStyle('enter');
    }
  };

  const optionHandler = (option: Option) => (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isLeaving) {
      setActiveOption(option.id);
      setLeaving(true);
      setTimeout(() => {
        setContainerStyle('leave');
        setTimeout(() => {
          setImageUrl('');
          appAnalytics.trackEvent(new SaveAnswer(question.id, option.id));
          dispatch(setAnswer(question.id, option.id, features));
        }, 200);
      }, 100);
    }
  };

  return {
    containerStyle,
    imageUrl,
    activeOption,
    optionHandler,
    revealImage,
  };
};
