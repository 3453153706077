import { Product } from 'bootstrap/data/product/Product';
import { TFunction } from 'react-i18next';
import { SuccessPaymentProps } from 'subscription/recurly/Recurly.types';
import { DefaultCardForm } from './defaultcardform/DefaultCardForm';
import { LayoutCardForm } from './layoutcardform/LayoutCardForm';
import { useFeatures } from 'useFeatures';

type Props = {
  product: Product;
  className?: string;
  darkTheme?: boolean;
  children?: JSX.Element | JSX.Element[];
  onSuccess: (params: SuccessPaymentProps) => void;
  onError: (message: string) => void;
  translation?: TFunction;
};

export const CardFormWrapper = ({
  product,
  children,
  onSuccess,
  onError,
  translation: t,
}: Props) => {
  const { isLayoutCardPaymentForm } = useFeatures();

  if (isLayoutCardPaymentForm) {
    return (
      <LayoutCardForm product={product} onError={onError} onSuccess={onSuccess}>
        {children}
      </LayoutCardForm>
    );
  }
  return (
    <DefaultCardForm
      product={product}
      onError={onError}
      onSuccess={onSuccess}
      translation={t}
    >
      {children}
    </DefaultCardForm>
  );
};
